import { i18n } from '@/plugins/i18n'
import { features } from '@/util/features'

export default [
  {
    meta: {
      title: i18n.tc('Setting'),
      default: true
    },
    path: '/settings',
    name: 'settings-index',
    component: () => import(/* webpackChunkName: "settings-index" */ '@/views/settings/Index.vue')
  },
  /* -------------------------------------------------------------------------- */
  /*                      Inicio de la vista configuración                      */
  /* -------------------------------------------------------------------------- */

  /* -------------------------- Configuración general - (GeneralSetting) ------------------------- */
  {
    meta: {
      title: i18n.tc('GeneralSetting')
    },
    path: '/settings/my-extended',
    name: 'settings-my-extended',
    component: () => import(/* webpackChunkName: "setups-my-extended" */ '@/views/settings/MyExtended.vue')
  },

  /* --------------- Etiquetas de terceros - (PersonTags) -------------- */
  {
    meta: {
      title: i18n.tc('PersonGroup')
    },
    path: '/settings/person-tags',
    name: 'settings-person-tags',
    component: () => import(/* webpackChunkName: "setups-person-tags-index" */ '@/views/settings/person-tags/Index.vue')
  },

  /* ---------------------------------- Logo ---------------------------------- */
  {
    meta: {
      title: i18n.tc('Logo', 1)
    },
    path: '/settings/logo',
    name: 'settings-logo',
    component: () => import(/* webpackChunkName: "setups-logos-index" */ '@/views/settings/logos/Index.vue')
  },

  /* #region -------------------- Sucursales - (Branches) ------------------- */
  {
    meta: {
      title: i18n.tc('Branch', 2)
    },
    path: '/settings/branches',
    name: 'settings-branches',
    component: () => import(/* webpackChunkName: "settings-branches-index" */ '@/views/settings/branches/Index.vue')
  },
  /* #endregion -------------------- Sucursales - (Branches) ------------------- */

  /* #region -------------------- Cajas - (Registers) ------------------- */
  {
    meta: {
      title: i18n.tc('Register', 2)
    },
    path: '/settings/branches/:branchId/registers',
    name: 'branch-registers',
    component: () => import(/* webpackChunkName: "registers-index" */ '@/views/settings/branches/registers/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('Register', 2)
    },
    path: '/settings/branches/registers',
    name: 'registers',
    component: () => import(/* webpackChunkName: "registers-index" */ '@/views/settings/branches/registers/Index.vue')
  },
  /* #endregion -------------------- Cajas - (Registers) ------------------- */

  /* #region -------------------- Accesos rápidos - (Layouts) ------------------- */
  {
    meta: {
      title: i18n.tc('Layout', 2)
    },
    path: '/settings/pos/layouts/:branchId',
    name: 'branch-layouts',
    component: () => import(/* webpackChunkName: "layouts-index" */ '@/views/settings/pos-layouts/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('Layout', 2)
    },
    path: '/settings/pos/layouts',
    name: 'layouts',
    component: () => import(/* webpackChunkName: "layouts-index" */ '@/views/settings/pos-layouts/Index.vue')
  },
  /* #endregion -------------------- Accesos rápidos - (Layouts) ------------------- */

  /* #region -------------------- Fidelización - (Loyalty) ------------------- */
  {
    meta: {
      title: i18n.tc('Loyalty')
    },
    path: '/settings/loyalty',
    name: 'loyalty',
    component: () => import(/* webpackChunkName: "loyalty-index" */ '@/views/settings/loyalty/Index.vue')
  },
  /* #endregion -------------------- Accesos rápidos - (Layouts) ------------------- */

  /* #region --------------------------- Impuestos - (Taxes) -------------------------- */
  {
    meta: {
      title: i18n.tc('Tax', 2)
    },
    path: '/settings/taxes',
    name: 'settings-taxes-index',
    component: () => import(/* webpackChunkName: "settings-taxes-index" */ '@/views/settings/taxes/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateTax')
    },
    path: '/settings/taxes/create',
    name: 'settings-taxes-create',
    component: () => import(/* webpackChunkName: "settings-taxes-create" */ '@/views/settings/taxes/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditTax')
    },
    path: '/settings/taxes/:id/edit',
    name: 'settings-taxes-edit',
    component: () => import(/* webpackChunkName: "settings-taxes-edit-index" */ '@/views/settings/taxes/Edit.vue')
  },
  /* #endregion --------------------------- Impuestos - (Taxes) -------------------------- */
  /* #region --------------------------- Retenciones - (Withholdings) -------------------------- */
  {
    meta: {
      title: i18n.tc('Withholding', 2)
    },
    path: '/settings/withholdings',
    name: 'settings-withholdings-index',
    component: () => import(/* webpackChunkName: "settings-withholdings-index" */ '@/views/settings/withholdings/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateWithholding')
    },
    path: '/settings/withholdings/create',
    name: 'settings-withholdings-create',
    component: () => import(/* webpackChunkName: "settings-withholdings-create" */ '@/views/settings/withholdings/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditWithholding')
    },
    path: '/settings/withholdings/:id/edit',
    name: 'settings-withholdings-edit',
    component: () => import(/* webpackChunkName: "settings-withholdings-edit" */ '@/views/settings/withholdings/Edit.vue')
  },
  /* #endregion --------------------------- Impuestos - (Taxes) -------------------------- */

  /* #region --------------------------- Emails --------------------------- */
  {
    meta: {
      title: i18n.tc('EmailSender')
    },
    path: '/settings/emails',
    name: 'settings-emails-index',
    component: () => import(/* webpackChunkName: "settings-emails-index" */ '@/views/settings/emails/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('EmailTemplate', 2)
    },
    path: '/settings/emails/template',
    name: 'settings-emails-template',
    component: () => import(/* webpackChunkName: "settings-emails-template" */ '@/views/settings/emails/Template.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateTemplate', 1)
    },
    path: '/settings/emails/template-create',
    name: 'settings-emails-template-create',
    component: () => import(/* webpackChunkName: "settings-emails-template-create" */ '@/views/settings/emails/TemplateCreate.vue')
  },
  {
    meta: {
      title: i18n.tc('EditTemplate', 1)
    },
    path: '/settings/emails/template-edit/id=:id',
    name: 'settings-emails-template-edit',
    component: () => import(/* webpackChunkName: "settings-emails-template-edit" */ '@/views/settings/emails/TemplateEdit.vue')
  },
  /* #endregion --------------------------- Emails --------------------------- */

  /* #region --------------------------- Comisiones - (Commissions) --------------------------- */
  {
    meta: {
      title: i18n.tc('Commission', 2)
    },
    path: '/settings/commissions',
    name: 'settings-commissions-index',
    component: () => import(/* webpackChunkName: "settings-commissions-index" */ '@/views/settings/commissions/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateCommission', 1)
    },
    path: '/settings/commissions/create',
    name: 'settings-commissions-create',
    component: () => import(/* webpackChunkName: "settings-commissions-create" */ '@/views/settings/commissions/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditCommission', 1)
    },
    path: '/settings/commissions/edit/id=:commissionId',
    name: 'settings-commissions-edit',
    component: () => import(/* webpackChunkName: "settings-commissions-edit" */ '@/views/settings/commissions/Edit.vue')
  },
  {
    meta: {
      title: i18n.tc('Consecutive', 2)
    },
    path: '/settings/consecutives',
    name: 'settings-consecutives-index',
    component: () => import(/* webpackChunkName: "settings-consecutives-index" */ '@/views/settings/consecutives/Index.vue')
  },
  /* #endregion --------------------------- Comisiones - (Commissions) --------------------------- */

  /* --------------------------- Etapas de entrega - (DeliveryStage) --------------------------- */
  {
    meta: {
      title: i18n.tc('DeliveryStage', 2)
    },
    path: '/settings/delivery-stages',
    name: 'setupsdelivery-stages',
    component: () => import(/* webpackChunkName: "settings-delivery-stages-index" */ '@/views/settings/delivery-stages/Index.vue')
  },

  /* --------------------- Medios de pago - (PaymentTypes) -------------------- */
  {
    meta: {
      title: i18n.tc('PaymentMean', 2)
    },
    path: '/settings/payment-types',
    name: 'settings-payment-types-index',
    component: () => import(/* webpackChunkName: "settings-payment-types-index" */ '@/views/settings/payment-types/Index.vue')
  },

  /* --------------------- Listas de precios - (PriceLists) -------------------- */
  {
    meta: {
      title: i18n.tc('PriceList', 2)
    },
    path: '/settings/price-lists',
    name: 'settings-price-lists-index',
    component: () => import(/* webpackChunkName: "settings-priceLists-index" */ '@/views/settings/price-lists/Index.vue')
  },

  /* --------------------- Recordatorios de pago - (PaymentReminders) -------------------- */
  {
    meta: {
      title: i18n.tc('PaymentReminder', 2)
    },
    path: '/settings/payment-reminders',
    name: 'settings-payment-reminders-index',
    component: () => import(/* webpackChunkName: "settings-payment-reminders-index" */ '@/views/settings/payment-reminders/Index.vue')
  },

  /* --------------------- Compañías - (Companies) -------------------- */
  {
    meta: {
      title: i18n.tc('VirtualZone')
    },
    path: '/settings/companies/zona-virtual',
    name: 'settings-companies-zona-virtual',
    component: () => import(/* webpackChunkName: "settings-companies-zona-virtual" */ '@/views/settings/companies/ZonaVirtual.vue')
  },
  {
    meta: {
      title: i18n.tc('DigitalSignature')
    },
    path: '/settings/companies/biz-electronic',
    name: 'settings-companies-biz-electronic',
    component: () => import(/* webpackChunkName: "settings-companies-biz-electronic" */ '@/views/settings/companies/BizElectronic.vue')
  },

  /* --------------------- Resoluciones - (Resolutions) -------------------- */
  {
    meta: {
      title: i18n.tc('Resolution', 2)
    },
    path: '/settings/resolutions',
    name: 'settings-resolutions',
    component: () => import(/* webpackChunkName: "settings-resolutions-index" */ '@/views/settings/resolutions/Index.vue')
  },

  /* --------------------- Promociones - (Promotions) -------------------- */
  {
    meta: {
      title: i18n.tc('Promotion', 2)
    },
    path: '/settings/promotions',
    name: 'settings-promotions',
    component: () => import(/* webpackChunkName: "settings-promotion-index" */ '@/views/settings/promotions/Index.vue')
  },

  /* ------------------------- Bodegas - (Warehouses) ------------------------- */
  {
    meta: {
      title: i18n.tc('Warehouse', 2)
    },
    path: '/settings/warehouses',
    name: 'settings-warehouses-index',
    component: () => import(/* webpackChunkName: "settings-warehouses-index" */ '@/views/settings/warehouses/Index.vue')
  },

  /* ------------------------- Atributos - (Attributes) ------------------------- */
  {
    meta: {
      title: i18n.tc('Attribute', 2)
    },
    path: '/settings/attributes',
    name: 'settings-attributes-index',
    component: () => import(/* webpackChunkName: "settings-attributes-index" */ '@/views/settings/attributes/Index.vue')
  },

  /* ------------------------ Categorías - (Categories) ----------------------- */
  {
    meta: {
      title: i18n.tc('Category', 2)
    },
    path: '/settings/item-categories',
    name: 'settings-item-categories-index',
    component: () => import(/* webpackChunkName: "settings-item-categories-index" */ '@/views/settings/item-categories/Index.vue')
  },

  /* ---------------------------- Marcas - (Brands) --------------------------- */
  {
    meta: {
      title: i18n.tc('Brand', 2)
    },
    path: '/settings/brands',
    name: 'settings-brands',
    component: () => import(/* webpackChunkName: "settings-brands-index" */ '@/views/settings/brands/Index.vue')
  },

  /* ----------------- Unidades de medida - (MeasurementUnits) ---------------- */
  {
    meta: {
      title: i18n.tc('MeasurementUnit', 2)
    },
    path: '/settings/measurement-units',
    name: 'settings-measurement-units',
    component: () => import(/* webpackChunkName: "settings-measurement-units-index" */ '@/views/settings/measurement-units/Index.vue')
  },

  /* ----------------- Formatos de información exógena - (Exogenas) ---------------- */
  {
    meta: {
      title: i18n.tc('Setting') + ' - ' + i18n.tc('ExogenousInformationFormat')
    },
    path: '/settings/exogenas',
    name: 'settings-exogenas',
    component: () => import(/* webpackChunkName: "settings-exogenas-index" */ '@/views/settings/exogenas/Index.vue')
  },

  /* -------------------- Centros de costos - (CostCesters) ------------------- */
  {
    meta: {
      title: i18n.tc('CostCenter', 2)
    },
    path: '/settings/cost-centers',
    name: 'settings-cost-centers',
    component: () => import(/* webpackChunkName: "settings-cost-centers-index" */ '@/views/settings/cost-centers/Index.vue')
  },

  /* -------------------- Conceptos bancarios - (bankConcepts) ------------------- */
  {
    meta: {
      title: i18n.tc('BankConcept', 2)
    },
    path: '/settings/bank-concepts',
    name: 'settings-bank-concepts',
    component: () => import(/* webpackChunkName: "settings-bank-concepts-index" */ '@/views/settings/bank-concepts/Index.vue')
  },

  /* --------------- Tipos de comprobantes - (JournalEntryTypes) -------------- */
  {
    meta: {
      title: i18n.tc('VoucherType', 2)
    },
    path: '/settings/journal-entry-types',
    name: 'settings-journal-entry-types',
    component: () => import(/* webpackChunkName: "settings-journal-entry-types-index" */ '@/views/settings/journal-entry-types/Index.vue')
  },

  /* --------------- Periodos fiscales - (FiscalPeriods) -------------- */
  {
    meta: {
      title: i18n.tc('FiscalPeriod', 2)
    },
    path: '/settings/fiscal-periods',
    name: 'setupsFiscalPeriods',
    component: () => import(/* webpackChunkName: "settings-fiscalPeriods-index" */ '@/views/settings/fiscal-periods/Index.vue')
  },

  /* --------------- Configuración Nómina Electrónica (ElectronicPayrollConfiguration) -------------- */
  {
    meta: {
      title: i18n.tc('ElectronicPayrollConfiguration')
    },
    path: '/settings/payroll-lite',
    name: 'settings-payroll-lite-index',
    component: () => import(/* webpackChunkName: "setups-payroll-lite-index" */ '@/views/settings/payroll-lite/Index.vue')
  },

  /* --------------- Etapas de producción (ProductionStage) -------------- */
  {
    meta: {
      title: i18n.tc('ProductionStage', 2)
    },
    path: '/settings/production-stage',
    name: 'settings-production-stage',
    component: () => import(/* webpackChunkName: "setups-production-stage-index" */ '@/views/settings/production-stage/Index.vue')
  },

  /* --------------- Roles -------------- */
  {
    meta: {
      title: i18n.tc('Rol', 2),
      get featureValue () { return features()?.planRoleBasedSecurity || features()?.planPosRoleBasedSecurity }
    },
    path: '/settings/roles',
    name: 'settings-roles',
    component: () => import(/* webpackChunkName: "setups-roles-index" */ '@/views/settings/roles/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('Privilege', 1),
      get featureValue () { return features()?.planRoleBasedSecurity || features()?.planPosRoleBasedSecurity }
    },
    path: '/settings/roles/:id/permission/',
    name: 'settings-roles-permission',
    component: () => import(/* webpackChunkName: "setups-roles-permission" */ '@/views/settings/roles/Permission.vue')
  },

  /* --------------------------- Usuarios - (Users) --------------------------- */
  {
    meta: {
      title: i18n.tc('User', 2)
    },
    path: '/settings/users',
    name: 'settings-users-index',
    component: () => import(/* webpackChunkName: "setups-users-index" */ '@/views/settings/users/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateUser', 1)
    },
    path: '/settings/users/create',
    name: 'settings-users-create',
    component: () => import(/* webpackChunkName: "setups-users-create" */ '@/views/settings/users/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditUser', 1)
    },
    path: '/settings/users/:id/edit',
    name: 'settings-users-edit',
    component: () => import(/* webpackChunkName: "setups-users-edit" */ '@/views/settings/users/Edit.vue')
  },
  {
    meta: {
      title: i18n.tc('UserSetting')
    },
    path: '/settings/users/:id/setting',
    name: 'settings-users-setting',
    component: () => import(/* webpackChunkName: "setups-users-setting" */ '@/views/settings/users/Setting.vue')
  },
  {
    meta: {
      title: i18n.tc('Region', 2)
    },
    path: '/settings/regions',
    name: 'settings-regions',
    component: () => import(/* webpackChunkName: "setups-regions" */ '@/views/settings/Regions.vue')
  },
  {
    meta: {
      title: i18n.tc('City', 2)
    },
    path: '/settings/cities',
    name: 'settings-cities',
    component: () => import(/* webpackChunkName: "setups-cities" */ '@/views/settings/Cities.vue')
  },
  {
    meta: {
      title: i18n.tc('Country', 2)
    },
    path: '/settings/countries',
    name: 'settings-countries',
    component: () => import(/* webpackChunkName: "setups-countries" */ '@/views/settings/Countries.vue')
  },
  {
    meta: {
      title: i18n.tc('MyCompany')
    },
    path: '/settings/my-company',
    name: 'settings-my-company',
    component: () => import(/* webpackChunkName: "setups-my-company" */ '@/views/settings/MyCompany.vue')
  },
  {
    meta: {
      title: i18n.tc('MyAccount')
    },
    path: '/settings/my-info',
    name: 'settings-my-info',
    component: () => import(/* webpackChunkName: "setups-my-info" */ '@/views/settings/MyInfo.vue')
  },
  {
    meta: {
      title: i18n.tc('CustomState', 2)
    },
    path: '/settings/status-reason',
    name: 'settings-status-reason',
    component: () => import(/* webpackChunkName: "status-reasons-index" */ '@/views/settings/status-reasons/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('Security')
    },
    path: '/settings/security',
    name: 'settings-security-index',
    component: () => import(/* webpackChunkName: "settings-security-index" */ '@/views/settings/security/Index.vue')
  }
  /* -------------------- Fin de la vista configuración -------------------- */
  // {
  //   meta: {
  //     title: i18n.tc('Layout', 2)
  //   },
  //   path: '/settings/branches/registers/:registerId/layouts',
  //   name: 'register-layouts',
  //   component: () => import(/* webpackChunkName: "settings-layouts-index" */ '@/views/settings/branches/registers/layouts/Index.vue')
  // }
  // /* -------------------- Fin de la vista configuración -------------------- */
]
