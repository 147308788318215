import { i18n } from '@/plugins/i18n'

/* --------------------------- Usuarios - (Users) --------------------------- */
export default [
  {
    meta: {
      title: i18n.tc('User', 2)
    },
    path: '/settings/users',
    name: 'settings-users-index',
    component: () => import(/* webpackChunkName: "setups-users-index" */ '@/views/settings/users/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CreateUser', 1)
    },
    path: '/settings/users/create',
    name: 'settings-users-create',
    component: () => import(/* webpackChunkName: "setups-users-create" */ '@/views/settings/users/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('EditUser', 1)
    },
    path: '/settings/users/:id/edit',
    name: 'settings-users-edit',
    component: () => import(/* webpackChunkName: "setups-users-edit" */ '@/views/settings/users/Edit.vue')
  },
  {
    meta: {
      title: i18n.tc('UserSetting')
    },
    path: '/settings/users/:id/setting',
    name: 'settings-users-setting',
    component: () => import(/* webpackChunkName: "setups-users-setting" */ '@/views/settings/users/Setting.vue')
  },
  {
    meta: {
      title: i18n.tc('MyCompany')
    },
    path: '/settings/my-company',
    name: 'settings-my-company',
    component: () => import(/* webpackChunkName: "setups-my-company" */ '@/views/settings/MyCompany.vue')
  },
  {
    meta: {
      title: i18n.tc('MyAccount')
    },
    path: '/settings/my-info',
    name: 'settings-my-info',
    component: () => import(/* webpackChunkName: "setups-my-info" */ '@/views/settings/MyInfo.vue')
  },
  {
    meta: {
      title: i18n.tc('Security')
    },
    path: '/settings/security',
    name: 'settings-security-index',
    component: () => import(/* webpackChunkName: "settings-security-index" */ '@/views/settings/security/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('Logo', 1)
    },
    path: '/settings/logo',
    name: 'settings-logo',
    component: () => import(/* webpackChunkName: "setups-logos-index" */ '@/views/settings/logos/Index.vue')
  }
]
