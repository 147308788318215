/* Core */
import Vue from 'vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import 'bulma-list/css/bulma-list.css'
import '@creativebulma/bulma-divider/dist/bulma-divider.css'
import '@creativebulma/bulma-badge/dist/bulma-badge.css'
import { i18n } from '@/plugins/i18n'
import pinia from '@/store/pinia'
import '@/directives'
/* Router & Store */
import router from '@/router'
import store from '@/store'
import { mapGetters, mapActions } from 'vuex'
/** VEE VALIDATE */
import '@/VeeValidate'
import '@/luxon'
import '@/apex-charts'
import moment from 'moment/moment'
import AppBreadcrumb from '@/components/AppBreadcrumb.vue'
import '@/registerServiceWorker'
import Clipboard from 'v-clipboard'
/** Icono para mostrar si un registro esta activo o inactivo */
import AppIconEnabled from '@/components/AppIconEnabled.vue'

Vue.use(Clipboard)

const App = () => import('@/App.vue')

moment.locale('es')

/* Composition API */
// Vue.use(VueCompositionAPI)

/* Default title tag */
const defaultDocumentTitle = 'Aliaddo'

/* Detect mobile layout */
store.dispatch('layoutMobileToggle')

window.onresize = () => {
  store.dispatch('layoutMobileToggle')
}

/* Collapse mobile aside menu on route change & set title */
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)
  store.commit('overlayToggle', false)
  store.commit('asideActiveForcedKeyToggle', null)

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} - ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

Vue.config.productionTip = false

// Allow `<zapier-zap-templates>` custom element
// -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
// Vue.config.ignoredElements = ['zapier-zap-templates']
Vue.config.ignoredElements = ['zapier-full-experience']

Vue.use(Buefy, {
  defaultIconPack: 'fas'
})
Vue.component('AppBreadcrumb', AppBreadcrumb)
Vue.component('AppIconEnabled', AppIconEnabled)
export const mixin = {
  methods: mapActions('modSubscription', ['setFeatures', 'setSubscriptionId']),
  computed: mapGetters('modSubscription', ['getFeatures', 'getPlan'])
}

Vue.mixin(mixin)
new Vue({
  router,
  store,
  i18n,
  pinia,
  render: h => h(App)
}).$mount('#app')
