import { alertWarning } from '@/util/alerts'
import getTokenNbxr from '@/store/getTokenNbxr'
export { goToElement } from './dom'

/** @typedef { import("@/models/erp-branch").default } Branch */
/** @typedef { import("@/models/common-person").Person } Person */

export function parseFloatToDecimal (value, decimals = 2) {
  if (!value) {
    value = 0
  } else {
    value = parseFloat(value)
  }

  if (value >= 0) {
    return (Math.round(value * 10 ** decimals) / 10 ** decimals)
  } else {
    return -(Math.round(parseFloat(Math.abs(value)) * 10 ** decimals) / 10 ** decimals)
  }
}

export function parseFloat6 (value) {
  return parseFloatToDecimal(value, 6)
}

export function toDecimal2 (value) {
  // eslint-disable-next-line no-useless-escape
  let valueReplace = ReplaceData(value.toString().replace(/(?!^-)[^0-9\.]/g, ''), ',', '')
  const valuesSplice = valueReplace.split('.')

  if (valuesSplice.length > 1) {
    if (valuesSplice[1].length > 2) {
      valueReplace = valuesSplice[0] + '.' + valuesSplice[1].substr(0, 2)
    }
  }

  if (value !== valueReplace) {
    value = valueReplace
  }

  return value
}

export function getSettingDocumentType (bizDocumentType) {
  switch (bizDocumentType) {
    case 'POS': return 'posInvoice'

    case 'INS': return 'invoiceComputer'
    case 'INE': return 'invoiceElectronic'
    case 'INC': return 'invoiceContingency'
    case 'NTC': return 'invoiceCreditNote'
    case 'NTD': return 'invoiceDebitNote'
    case 'RMS': return 'salesRemission'
    case 'ORS': return 'salesOrder'
    case 'QTE': return 'quote'
    case 'RCS': return 'salesConsignmentRemission'

    case 'INP': return 'bill'
    case 'RFP': return 'billDebitNote'
    case 'NCP': return 'billCreditNote'
    case 'RMP': return 'purchaseRemission'
    case 'ORP': return 'purchaseOrder'
    case 'RCP': return 'purchaseConsignmentRemission'

    default: return null
  }
}

/**
* Organiza un arreglo de objetos tomando como
* base una propiedad de los objetos del arreglo. Ej:
*
* array = [{ key: 'My string value' }, { key: 'Hello world!' }]
*
* array = dynamicSortObjectArrayAlphabetically(array)
* // resultado -> [{ key: 'Hello world!' }, { key: 'My string value' }]
*
* @param {[object]} array Arreglo de objetos a organizar
*
* @param {string} property propiedad que será tomada para
* organizar el arreglo de objetos
*
* @returns {[object]}
*/
export function dynamicSortObjectArrayAlphabetically (array, property) {
  let sortOrder = 1

  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }

  return array.sort(function (a, b) {
    if (sortOrder === -1) {
      return b[property].localeCompare(a[property])
    } else {
      return a[property].localeCompare(b[property])
    }
  })
}

/**
* Recibe un texto y lo retorna sin los diacríticos (ej: ú, ü, ó, ö, ñ)
*
* @param {String} text
* @returns {String}
*/
export function noDiacritics (text) {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

/**
 * @param {Branch} branch
 * @returns {String}
 */
export function branchAddress (branch) {
  const parts = []

  branch.countryName && parts.push(branch.countryName)
  branch.regionName && parts.push(branch.regionName)
  branch.cityName && parts.push(branch.cityName)
  branch.branchAddress && parts.push(branch.branchAddress)

  return parts.join(', ')
}

/**
 * @param {Person} person
 * @returns {String}
 */
export function personFullIdentification (person) {
  if (!person || !person.personIdentification) return null

  const parts = []

  person.identificationTypeName && parts.push(person.identificationTypeName)
  person.personIdentification && parts.push(person.personIdentification)

  if (person.personIdentificationCheck && person.identificationTypeCode === '31') {
    return `${parts.join(' ')}-${person.personIdentificationCheck}`
  }

  return parts.join(' ')
}

/**
 * @param {Person} person
 * @returns {String}
 */
export function personName (person) {
  if (!person) return null
  if (person.personName) return person.personName

  const nameParts = []

  person.personFirstName && nameParts.push(person.personFirstName)
  person.personSecondName && nameParts.push(person.personSecondName)
  person.personFirstSurname && nameParts.push(person.personFirstSurname)
  person.personSecondSurname && nameParts.push(person.personSecondSurname)

  return nameParts.join(' ')
}

/* END NUMBER */
export function ReplaceData (data, remp, cart) {
  if (data) {
    if (remp === '[' || remp === ']') { remp = '[' + remp + ']' }
    return data.toString().replace(new RegExp(remp, 'g'), cart)
  } else { return data }
}

/* ValidateGuid */
export function isGuid (text) {
  const validGuid = /^(\{|\()?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}(\}|\))?$/
  const emptyGuid = /^(\{|\()?0{8}-(0{4}-){3}0{12}(\}|\))?$/
  return validGuid.test(text) && !emptyGuid.test(text)
}

export function uuid () {
  let dt = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
  return uuid
}

export function cookieNumber (value) {
  if (getTokenNbxr().companyCalculateDecimal) {
    return Math.round(parseFloat(value) * 10 ** 2) / 10 ** 2
  } else {
    if (value > 0) {
      return Math.round(value)
    } else {
      return -Math.round(Math.abs(value))
    }
  }
}

export function objectPropertiesToDefault (obj) {
  Object.keys(obj).forEach(function (prop) {
    switch (typeof obj[prop]) {
      case 'object':
        if (Array.isArray(obj[prop])) {
          obj[prop] = []
          return
        }

        if (!obj[prop]) {
          obj[prop] = null
          return
        }

        obj[prop] = new window[obj[prop].constructor.name]()
        break

      case 'string':
        obj[prop] = ''
        break

      case 'number':
        obj[prop] = 0
        break

      case 'boolean':
        obj[prop] = false
        break
    }
  })

  return obj
}

export function numberToLetters (num) {
  if (num >= 1000000000000000) {
    alertWarning('Se permite solo ingresar el valor en letras de un total menor a mil billones (1,000,000,000,000,000.00)!')
    return ''
  }

  const data = {
    numero: num,
    enteros: Math.floor(num),
    centavos: ((Math.round(num * 100)) - (Math.floor(num) * 100)),
    letrasCentavos: '',
    letrasMonedaPlural: 'PESOS', // "PESOS", 'Dólares', 'Bolívares', 'etcs'
    letrasMonedaSingular: 'PESO', // "PESO", 'Dólar', 'Bolivar', 'etc'
    letrasMonedaCentavoPlural: 'CENTAVOS',
    letrasMonedaCentavoSingular: 'CENTAVO'
  }

  if (data.centavos > 0) {
    data.letrasCentavos = 'CON ' + (function () {
      if (data.centavos === 1) { return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoSingular } else { return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoPlural }
    })()
  }

  if (data.enteros === 0) { return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos } else if (data.enteros === 1) { return 'UN ' + data.letrasMonedaSingular + ' ' + data.letrasCentavos } else if (data.enteros < 1000000) { return Miles(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos } else if (data.enteros < 1000000000) { return Millones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos } else if (data.enteros < 1000000000000) { return MilMillones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos } else { return Billones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos }
}

/* -------------------------------------------------------------------------- */
/*                              Números a letras                              */
/* -------------------------------------------------------------------------- */
export function Unidades (num) {
  switch (num) {
    case 1: return 'UN'
    case 2: return 'DOS'
    case 3: return 'TRES'
    case 4: return 'CUATRO'
    case 5: return 'CINCO'
    case 6: return 'SEIS'
    case 7: return 'SIETE'
    case 8: return 'OCHO'
    case 9: return 'NUEVE'
  }

  return ''
}// Unidades()

export function Decenas (num) {
  const decena = Math.floor(num / 10)
  const unidad = num - (decena * 10)

  switch (decena) {
    case 1:
      switch (unidad) {
        case 0: return 'DIEZ'
        case 1: return 'ONCE'
        case 2: return 'DOCE'
        case 3: return 'TRECE'
        case 4: return 'CATORCE'
        case 5: return 'QUINCE'
        default: return 'DIECI' + Unidades(unidad)
      }
    case 2:
      if (unidad === 0) {
        return 'VEINTE'
      } else {
        return 'VEINTI' + Unidades(unidad)
      }
    case 3: return DecenasY('TREINTA', unidad)
    case 4: return DecenasY('CUARENTA', unidad)
    case 5: return DecenasY('CINCUENTA', unidad)
    case 6: return DecenasY('SESENTA', unidad)
    case 7: return DecenasY('SETENTA', unidad)
    case 8: return DecenasY('OCHENTA', unidad)
    case 9: return DecenasY('NOVENTA', unidad)
    case 0: return Unidades(unidad)
  }
}

export function DecenasY (strSin, numUnidades) {
  if (numUnidades > 0) { return strSin + ' Y ' + Unidades(numUnidades) }

  return strSin
}// DecenasY()

export function Centenas (num) {
  const centenas = Math.floor(num / 100)
  const decenas = num - (centenas * 100)

  switch (centenas) {
    case 1:
      if (decenas > 0) { return 'CIENTO ' + Decenas(decenas) }
      return 'CIEN'
    case 2: return 'DOSCIENTOS ' + Decenas(decenas)
    case 3: return 'TRESCIENTOS ' + Decenas(decenas)
    case 4: return 'CUATROCIENTOS ' + Decenas(decenas)
    case 5: return 'QUINIENTOS ' + Decenas(decenas)
    case 6: return 'SEISCIENTOS ' + Decenas(decenas)
    case 7: return 'SETECIENTOS ' + Decenas(decenas)
    case 8: return 'OCHOCIENTOS ' + Decenas(decenas)
    case 9: return 'NOVECIENTOS ' + Decenas(decenas)
  }

  return Decenas(decenas)
}// Centenas()

export function Seccion (num, divisor, strSingular, strPlural) {
  const cientos = Math.floor(num / divisor)
  const resto = num - (cientos * divisor)
  let letras = ''

  if (cientos > 0) {
    if (cientos > 1) {
      letras = Centenas(cientos) + ' ' + strPlural
    } else {
      letras = strSingular
    }
  }

  if (resto > 0) {
    letras += ''
  }

  return letras
}// Seccion()

export function Miles (num) {
  const divisor = 1000
  const cientos = Math.floor(num / divisor)
  const resto = num - (cientos * divisor)
  const strMiles = Seccion(num, divisor, 'MIL', 'MIL')
  const strCentenas = Centenas(resto)

  if (strMiles === '') {
    return strCentenas
  }

  return strMiles + ' ' + strCentenas
}// Miles()

export function Millones (num) {
  if (num === 0) {
    return 'MILLONES DE'
  }

  const divisor = 1000000
  const cientos = Math.floor(num / divisor)
  const resto = num - (cientos * divisor)

  const strMillones = Seccion(num, divisor, millon(num, true), millon(num, false))
  const strMiles = Miles(resto)

  if (strMillones === '') {
    return strMiles
  }

  return strMillones + ' ' + strMiles
}// Millones()

export function MilMillones (num) {
  const divisor = 1000000000
  const cientos = Math.floor(num / divisor)
  const resto = num - (cientos * divisor)
  const strMiles = Seccion(num, divisor, 'MIL', 'MIL')
  const strMillones = Millones(resto)

  if (strMillones === '') {
    return strMiles
  }

  return strMiles + ' ' + strMillones
}// MilMillones()

export function millon (num, singular) {
  let letraMillon = ''
  if (singular === true) { letraMillon = 'UN MILLON' } else { letraMillon = 'MILLONES' }
  if (num % 1000000 === 0) { letraMillon = letraMillon + ' DE' }
  return letraMillon
}

export function Billones (num) {
  const divisor = 1000000000000
  const cientos = Math.floor(num / divisor)
  const resto = num - (cientos * divisor)
  const strBillones = Seccion(num, divisor, billon(num, true), billon(num, false))
  const strMiles = MilMillones(resto)

  if (resto === 0) {
    return strBillones
  }

  if (strBillones === '') {
    return strMiles
  }

  return strBillones + ' ' + strMiles
}// Billones()

export function billon (num, singular) {
  let letraBillon = ''
  if (singular === true) { letraBillon = 'UN BILLON' } else { letraBillon = 'BILLONES' }
  if (num % 1000000000000 === 0) { letraBillon = letraBillon + ' DE' }
  return letraBillon
}

export function getAllUrlParams (url) {
  // get query string from url (optional) or window
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1)

  // we'll store the parameters here
  const obj = {}

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0]

    // split our query string into its component parts
    const arr = queryString.split('&')

    for (let i = 0; i < arr.length; i++) {
      // separate the keys and the values
      const a = arr[i].split('=')

      // set parameter name and value (use 'true' if empty)
      let paramName = a[0]
      let paramValue = typeof (a[1]) === 'undefined' ? true : a[1]

      // (optional) keep case consistent
      paramName = paramName.toLowerCase()
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase()

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        const key = paramName.replace(/\[(\d+)?\]/, '')
        if (!obj[key]) obj[key] = []

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          const index = /\[(\d+)\]/.exec(paramName)[1]
          obj[key][index] = paramValue
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue)
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]]
          obj[paramName].push(paramValue)
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue)
        }
      }
    }
  }

  return obj
}

export function cookieBizCalculateNumber (value) {
  if (getTokenNbxr().BizDocumentCalculateDecimal) {
    return (Math.round(parseFloat(value) * 10 ** 2) / 10 ** 2)
  } else {
    if (value > 0) {
      return Math.round(value)
    } else {
      return -Math.round(Math.abs(value))
    }
  }
}

export function popUpScroll (page, width, height) {
  window.open(page, '_blank', 'status=0, width=' + width + ', height=' + height + ',menubar=0,scrollbars=1,toolbar=0,location=0,directories=0,resizable=1, top=100, left=200')
  return false
}

export function calcularDv (nit) {
  if (isNaN(nit)) {
    return ''
  } else {
    const arreglo = new Array(16)
    arreglo[1] = 3
    arreglo[2] = 7
    arreglo[3] = 13
    arreglo[4] = 17
    arreglo[5] = 19
    arreglo[6] = 23
    arreglo[7] = 29
    arreglo[8] = 37
    arreglo[9] = 41
    arreglo[10] = 43
    arreglo[11] = 47
    arreglo[12] = 53
    arreglo[13] = 59
    arreglo[14] = 67
    arreglo[15] = 71

    let x = 0
    let y = 0
    for (let i = 0; i < nit.length; i++) {
      y = nit.substr(i, 1)
      x += y * arreglo[nit.length - i]
    }
    y = x % 11
    let dv = 0
    if (y > 1) {
      dv = 11 - y
    } else {
      dv = y
    }
    return dv
  }
}

export function bindEvent (element, eventName, eventHandler) {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false)
  } else if (element.attachEvent) {
    element.attachEvent('on' + eventName, eventHandler)
  }
}
