import LocaleEn from 'apexcharts/dist/locales/en.json'
import LocaleEs from 'apexcharts/dist/locales/es.json'
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'

window.Apex.chart = {
  defaultLocale: navigator.language.split('-')[0],
  locales: [
    LocaleEn,
    LocaleEs
  ]
}

Vue.use(VueApexCharts)
