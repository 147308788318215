import { ToastProgrammatic as Toast } from 'buefy'
import { i18n } from '@/plugins/i18n'

export function alertError (msg) {
  Toast.open({
    message: msg,
    queue: false,
    type: 'is-danger',
    duration: 5000
  })
}

export function alertSuccess (msg) {
  Toast.open({
    message: msg,
    queue: false,
    type: 'is-success',
    duration: 5000
  })
}

export function alertWarning (msg) {
  Toast.open({
    message: msg,
    queue: false,
    type: 'is-warning',
    duration: 5000
  })
}

export function alertInfo (msg) {
  Toast.open({
    message: msg,
    queue: false,
    type: 'is-info',
    duration: 5000
  })
}

export function alertErrorApi (error) {
  // Si el error contiene un mensaje personalizado
  if (error && error.response && error.response.data && (error.response.data.exceptionMessage || error.response.data.message)) {
    // Imprime el mensaje personalizado para el usuario
    alertError('Oops, ' + (error.response.data.exceptionMessage || error.response.data.message))
  } else if (error.response.status === 403) {
    // Imprime el mensaje de error si es de permisos
    alertError(i18n.tc('NotPermittedCompany'))
  } else {
    // Imprime el mensaje de error por defecto
    alertError(i18n.tc('UnexpectedEvent'))
  }
}

export function validateForm (msg) {
  Toast.open({
    message: msg || i18n.tc('validateForm'),
    queue: false,
    type: 'is-info',
    duration: 5000
  })
}
