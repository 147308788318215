import { i18n } from '@/plugins/i18n'

export default [
  {
    meta: {
      title: i18n.tc('Import', 2)
    },
    path: '/import',
    name: 'import',
    component: () => import(/* webpackChunkName: "import" */ '@/views/import/Index.vue')

  }
]
