import { Buffer } from 'buffer'

/**
 * Información de la sesión actual
 *
 * @author Kristian Alonso Sanchez <kalonsos@aliaddo.com>
 *
 * @typedef {Object} VmSessionData
 *
 * #region Company
 *
 * @property {number} companyKey
 *
 * @property {number} serviceId ID del servicio:
 *  - 0: Aliaddo Hub
 *  - 1: Aliaddo FE - ELIMINADO
 *  - 2: Aliaddo ERP
 *  - 3: Aliaddo HRM
 *  - 7: Aliaddo FE ISV
 *  - 8: Aliaddo POS
 *  - 9: Aliaddo CRM
 *  - 10: Aliaddo Nomina Full
 *  - 11: Nómina Lite
 *  - 12: Aliaddo Nomina Isv
 *  - 13: Aliaddo Help
 *
 * @property {number} subscriptionId
 * @property {number} subscriptionIdHrmFull
 * @property {number} subscriptionIdPos
 * @property {boolean} companyHasKickOff
 * @property {boolean} companyMustUpdateData
 * @property {number} tenantId
 * @property {string} companyApiKey
 * @property {string} companyCountry
 * @property {string} companyCountryName
 * @property {string} companyRegionCode
 * @property {string} companyRegionName
 * @property {string} companyCityCode
 * @property {string} companyCityName
 * @property {string} companyLogo
 * @property {string} companyStatus
 * @property {boolean} companyIsPartner
 * @property {boolean} endTrialPeriod
 * @property {string} endPaquete
 * @property {string} endPaqueteMessage
 * @property {string} companyName
 * @property {string} companyIdentification
 * @property {string} companyPhone
 * @property {string} companyId
 * @property {string} companyServiceMode
 * @property {string} companyAddress
 * @property {decimal} companyServicePrice
 *
 * #endregion
 *
 * #region User
 *
 * @property {string} userId
 * @property {string} userFirstName
 * @property {string} userLastName
 * @property {string} userFullName
 * @property {string} userEmail
 * @property {string} userPhone
 * @property {boolean} userPasswordExpired
 *
 * #endregion
 *
 * #region Profile
 *
 * @property {number} roleId
 * @property {string} roleName
 * @property {string[]} userProfileBranches
 * @property {string[]} userProfileRegisters
 * @property {string[]} userProfileWarehouses
 *
 * #endregion
 *
 * #region CompanyExtended
 *
 * @property {boolean} companyHasDocumentPrefix
 * @property {string} currencyKey
 * @property {string} personId
 *
 * @property {boolean} companyIsPayVat
 * @property {boolean} companyIsAutoRetainer
 * @property {boolean} companyCalculateDecimal
 * @property {boolean} bizDocumentCalculateDecimal
 * @property {boolean} bizElectronicSignWithAliaddo
 * @property {boolean} companyWalletByPerson
 * @property {boolean} productionByControl
 * @property {string} measuresWeightItems
 * @property {string} measuresSizeItems
 *
 * #endregion
 *
 * #region Plan
 *
 * @property {string} planName
 * @property {keyof typeof import('@/models/enums/plan-mode').PlanMode} planMode
 * @property {bool} planFree
 *
 * @property {number} planElectronicTriplet TRIPLETA ELECTRONICA
 * @property {number} planLogistic Logística
 * @property {number} planSupportDocument Documento soporte
 * @property {number} planAccounting Contabilidad
 * @property {number} planItem Items inventariados
 * @property {number} planSell Facturas electronicas
 * @property {number} planElectronicBill 57 2 (NULL) Facturación electrónica planElectronicBill  OpenLimit
 * @property {number} planPointOfSale Puntos de venta
 *
 * @property {number} planCrm
 * @property {number} planInboxManual
 * @property {number} planInboxAutomatic
 * @property {number} planInboxControl
 * @property {number} planHrmFull
 * @property {number} planCostCenter
 * @property {number} planEmailSent
 * @property {number} planExportCertificateMassive
 * @property {number} planBizConsignation
 * @property {number} planBuy
 *
 * -------------------------------------------------------------------------- *
 *                                  Hrm Lite                                  *
 * -------------------------------------------------------------------------- *
 * @property {number} planElectronicPayrollErp
 *
 * -------------------------------------------------------------------------- *
 *                                    POS                                     *
 * -------------------------------------------------------------------------- *
 * @property {number} hasAliaddoPos Si tiene el servicio de POS
 * @property {number} planDocumentPos Controla los documentos que no son electronicos del servicio ERP
 * @property {number} planPosDocuments Este atributo controla las cantidades de documentos cuando se tiene el servicio de AliaddoPOS, controla equivalente y electronico
 * @property {number} planPosPurchases Este atributo controla si tiene compras en planes solo pos
 * @property {number} planElectronicPos Controla los documentos pos cuando son electronicos en el servicio ERP
 *
 * #endregion
**/

/**
 * Consúlta la información de la sesión actual desde el `LocalStorage` del navegador. Contiene información de:
 * - Empresa
 * - Usuario
 * - Perfil del usuario
 * - Configuración global de la empresa
 * - Plan
 *
 * @returns {VmSessionData}
 */
export default function getTokenNbxr () {
  return localStorage.getItem('dataCompany') ? JSON.parse(Buffer.from(localStorage.getItem('dataCompany'), 'base64').toString('utf-8')) : null
}
