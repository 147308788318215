import { i18n } from '@/plugins/i18n'
import { features } from '@/util/features'
import getTokenNbxr from '@/store/getTokenNbxr'

export default [
  {
    meta: {
      title: i18n.tc('DeskKind', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/kinds',
    name: 'desk-kinds-index',
    component: () => import(/* webpackChunkName: "kinds-index" */ '@/views/desk/settings/kinds/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('Origin', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/origins',
    name: 'desk-origins-index',
    component: () => import(/* webpackChunkName: "desk-kinds-index" */ '@/views/desk/settings/origins/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('Tag', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/tags',
    name: 'desk-tags-index',
    component: () => import(/* webpackChunkName: "desk-tags-index" */ '@/views/desk/settings/tags/Index.vue')
  },
  /* #region --------------------------- Estatus --------------------------- */

  {
    meta: {
      title: i18n.tc('Status', 2),
      get featureValue () { return features()?.planHelpPersonalizeStatuses && features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/status',
    name: 'desk-status-index',
    component: () => import(/* webpackChunkName: "desk-status-index" */ '@/views/desk/settings/status/Index.vue')
  },
  /* #region --------------------------- POLITICAS SLA --------------------------- */
  {
    meta: {
      title: i18n.tc('Agreement', 2),
      get featureValue () { return features()?.planHelpMultiplesPolicesSla && features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/agreements',
    name: 'desk-agreements-index',
    component: () => import(/* webpackChunkName: "desk-agreements-index" */ '@/views/desk/settings/agreements/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('Agreement', 2),
      get featureValue () { return features()?.planHelpMultiplesPolicesSla && features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/agreements/create',
    name: 'desk-agreements-create',
    component: () => import(/* webpackChunkName: "desk-agreements-create" */ '@/views/desk/settings/agreements/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('Agreement', 2),
      get featureValue () { return features()?.planHelpMultiplesPolicesSla && features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/agreements/update/:id',
    name: 'desk-agreements-update',
    component: () => import(/* webpackChunkName: "desk-agreements-update" */ '@/views/desk/settings/agreements/Update.vue'),
    props: true
  },
  {
    meta: {
      get featureValue () { return features()?.planHelpHasPortal ? -1 : 0 }
    },
    path: '/desk/solutions/manage/categories/:id/folders/:folderId',
    name: 'desk-solutions-manage-categories',
    component: () => import(/* webpackChunkName: "desk-solutions-manage-id" */ '@/views/desk/solutions/Manage.vue'),
    props: true
  },
  {
    meta: {
      title: i18n.tc('Solution', 2),
      get featureValue () { return features()?.planHelpHasPortal ? -1 : 0 }
    },
    path: '/desk/solutions',
    name: 'desk-solutions-index',
    component: () => import(/* webpackChunkName: "desk-solutions-index" */ '@/views/desk/solutions/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('Solution', 2),
      get featureValue () { return features()?.planHelpHasPortal ? -1 : 0 }
    },
    path: '/desk/solutions/manage/categories/:id',
    name: 'desk-solutions-manage',
    component: () => import(/* webpackChunkName: "desk-solutions-manage" */ '@/views/desk/solutions/Manage.vue')
  },
  {
    meta: {
      title: i18n.tc('Solution', 2),
      get featureValue () { return features()?.planHelpHasPortal ? -1 : 0 }
    },
    path: '/desk/solutions/articles/create',
    name: 'desk-solutions-create-articles',
    component: () => import(/* webpackChunkName: "desk-solutions-Create-articles" */ '@/views/desk/solutions/articles/CreateArticle.vue')
  },
  {
    meta: {
      title: i18n.tc('Solution', 2),
      get featureValue () { return features()?.planHelpHasPortal ? -1 : 0 }
    },
    path: '/desk/solutions/articles/category/:id/folders/:folderId',
    name: 'create-articles',
    component: () => import(/* webpackChunkName: "create-articles" */ '@/views/desk/solutions/articles/CreateArticle.vue')
  },
  {
    meta: {
      title: i18n.tc('Solution', 2),
      get featureValue () { return features()?.planHelpHasPortal ? -1 : 0 }
    },
    path: '/desk/solutions/articles/:id/edit',
    name: 'desk-solutions-edit-articles',
    component: () => import(/* webpackChunkName: "desk-solutions-edit-articles" */ '@/views/desk/solutions/articles/CreateArticle.vue')
  },
  {
    meta: {
      title: i18n.tc('Solution', 2),
      get featureValue () { return features()?.planHelpHasPortal ? -1 : 0 }
    },
    path: '/desk/solutions/articles/:id/show',
    name: 'desk-solutions-show-article',
    component: () => import(/* webpackChunkName: "desk-solutions-show-article" */ '@/views/desk/solutions/articles/ShowArticle.vue')
  },
  /* #region --------------------------- Modulo Horarios --------------------------- */
  {
    meta: {
      title: i18n.tc('BusinessHour', 2),
      get featureValue () { return features()?.planHelpMultipleWorkingHours && features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/workings-times',
    name: 'desk-workings-times-index',
    component: () => import(/* webpackChunkName: "desk-workings-times-index" */ '@/views/desk/settings/workings-times/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('BusinessHour', 2),
      get featureValue () { return features()?.planHelpMultipleWorkingHours && features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/workings-times/create',
    name: 'desk-workings-times-create',
    component: () => import(/* webpackChunkName: "desk-workings-times-create" */ '@/views/desk/settings/workings-times/Create.vue')

  },
  {
    meta: {
      title: i18n.t('BusinessHour'),
      get featureValue () { return features()?.planHelpMultipleWorkingHours && features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/workings-times/edit/:id',
    name: 'desk-workings-times-edit',
    component: () => import(/* webpackChunkName: "desk-workings-times-edit" */ '@/views/desk/settings/workings-times/Create.vue'),
    props: true
  },
  /* #region --------------------------- END Mod Horarios --------------------------- */
  {
    meta: {
      title: i18n.tc('Group', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/groups',
    name: 'desk-groups-index',
    component: () => import(/* webpackChunkName: "desk-groups-index" */ '@/views/desk/settings/groups/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('Group', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/groups/edit/:id',
    name: 'desk-groups-edit',
    component: () => import(/* webpackChunkName: "desk-groups-edit" */ '@/views/desk/settings/groups/Update.vue')

  },
  {
    meta: {
      title: i18n.tc('EmailNotification', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/email-notifications',
    name: 'desk-email-notifications',
    component: () => import(/* webpackChunkName: "desk-emailNotifications" */ '@/views/desk/settings/email-notifications/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('EmailNotification', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/email-notifications/:id/edit/',
    name: 'desk-email-notifications-edit',
    component: () => import(/* webpackChunkName: "desk-desk-emailNotifications-edit" */ '@/views/desk/settings/email-notifications/EditEmailNotification.vue')
  },
  {
    meta: {
      title: i18n.tc('Survey', 2),
      get featureValue () { return features()?.planHelpSatisfactionSurveys && features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/surveys',
    name: 'desk-surveys',
    component: () => import(/* webpackChunkName: "desk-surveys" */ '@/views/desk/settings/surveys/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('Survey', 2),
      get featureValue () { return features()?.planHelpSatisfactionSurveys && features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/survey/create',
    name: 'desk-survey-create',
    component: () => import(/* webpackChunkName: "desk-survey-create" */ '@/views/desk/settings/surveys/CreateSurvey.vue')
  },
  {
    meta: {
      title: i18n.tc('Survey', 2),
      get featureValue () { return features()?.planHelpSatisfactionSurveys && features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/survey/:id/edit/',
    name: 'desk-survey-edit',
    component: () => import(/* webpackChunkName: "desk-survey-edit" */ '@/views/desk/settings/surveys/CreateSurvey.vue')
  },
  {
    meta: {
      title: i18n.tc('CannedResponse', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/canned-responses',
    name: 'desk-canned-responses',
    component: () => import(/* webpackChunkName: "desk-canned-responses" */ '@/views/desk/settings/canned-responses/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CannedResponse', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/canned-responses/:id/folders/:folderId',
    name: 'canned-responses',
    component: () => import(/* webpackChunkName: "desk-canned-responses" */ '@/views/desk/settings/canned-responses/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CannedResponse', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/canned-responses/folders/:id',
    name: 'canned-responses-folder',
    component: () => import(/* webpackChunkName: "desk-canned-responses-folder" */ '@/views/desk/settings/canned-responses/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('CannedResponse', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/canned-responses/create',
    name: 'desk-canned-responses-create',
    component: () => import(/* webpackChunkName: "desk-canned-responses-create" */ '@/views/desk/settings/canned-responses/CreateCannedResponses.vue')
  },
  {
    meta: {
      title: i18n.tc('CannedResponse', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/canned-responses/:id/edit/',
    name: 'desk-canned-responses-edit',
    component: () => import(/* webpackChunkName: "desk-canned-responses-edit" */ '@/views/desk/settings/canned-responses/CreateCannedResponses.vue')
  },
  /* #region --------------------------- TICKETS --------------------------- */
  {
    meta: {
      title: i18n.t('Tickets', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/tickets',
    name: 'desk-tickets-index',
    component: () => import(/* webpackChunkName: "desk-tickets-index" */ '@/views/desk/tickets/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('Tickets', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/tickets/create',
    name: 'desk-tickets-create',
    component: () => import(/* webpackChunkName: "desk-tickets-create" */ '@/views/desk/tickets/Create.vue')
  },
  {
    meta: {
      title: i18n.tc('Tickets', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/tickets/responses/:id',
    name: 'desk-tickets-responses',
    component: () => import(/* webpackChunkName: "desk-tickets-edit" */ '@/views/desk/tickets/Responses.vue')
  },
  {
    meta: {
      title: i18n.tc('Tickets', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/tickets/edit/:id',
    name: 'desk-tickets-edit',
    component: () => import(/* webpackChunkName: "desk-tickets-edit" */ '@/views/desk/tickets/Edit.vue')
  },
  // /* CAMPOS DEL TICKET */
  // {
  //   meta: {
  //     title: i18n.t('TicketFields')
  //   },
  //   path: '/desk/settings/setting-ticket-field',
  //   name: 'desk-setting-ticket-field-index',
  //   component: () => import(/* webpackChunkName: "desk-setting-ticket-field-index" */ '@/views/desk/settings/setting-ticket-field/Index.vue')
  // },
  /* reporte de tickets */
  {
    meta: {
      title: i18n.tc('Panel', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/tickets/panel',
    name: 'desk-tickets-panel',
    component: () => import(/* webpackChunkName: "desk-report" */ '@/views/desk/tickets/Panel.vue')

  },
  // {
  //   meta: {
  //     title: i18n.tc('Report', 2)
  //   },
  //   path: '/desk/tickets/response-details/:id',
  //   name: 'desk-response-details',
  //   component: () => import(/* webpackChunkName: "desk-response-details" */ '@/views/desk/tickets/ResponseDetails.vue')

  // },
  {
    meta: {
      title: i18n.tc('Portal', 2),
      get featureValue () { return features()?.planHelpHasPortal && features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/portal',
    name: 'desk-portal-index',
    component: () => import(/* webpackChunkName: "desk-portal-index" */ '@/views/desk/settings/portals/Index.vue')

  },
  {
    meta: {
      title: i18n.tc('Portal', 2),
      get featureValue () { return features()?.planHelpHasPortal && features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/portal/edit/:id',
    name: 'desk-portal-edit',
    component: () => import(/* webpackChunkName: "desk-portal-edit" */ '@/views/desk/settings/portals/EditPortal.vue')
  },
  {
    meta: {
      title: i18n.tc('CustomerService', 2),
      get featureValue () { return [11, 12, 1, 2, 3].includes(getTokenNbxr().roleId) && features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/customer-service-setting',
    name: 'desk-customer-service-setting',
    component: () => import(/* webpackChunkName: "desk-customer-service-setting" */ '@/views/desk/index.vue')

  },
  {
    meta: {
      title: i18n.tc('SurveyDetails', 2),
      get featureValue () { return (features()?.planHelpSatisfactionSurveys) ? -1 : 0 }
    },
    path: '/desk/report/survey-details',
    name: 'desk-survey-details',
    component: () => import(/* webpackChunkName: "desk-survey-details" */ '@/views/desk/tickets/DetailSurvey.vue')

  },
  {
    meta: {
      title: i18n.tc('ReportArticle', 2),
      get featureValue () { return features()?.planHelpHasPortal ? -1 : 0 }
    },
    path: '/desk/report/articles',
    name: 'desk-report-articles',
    component: () => import(/* webpackChunkName: "desk-report-articles" */ '@/views/desk/reports/Article.vue')

  },
  {
    meta: {
      title: i18n.tc('PerformanseAgent', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/report/agent',
    name: 'desk-report-agent',
    component: () => import(/* webpackChunkName: "desk-report-agent" */ '@/views/desk/reports/agentPerformance.vue')

  },
  {
    meta: {
      title: i18n.tc('PerformanseGroup', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/report/group',
    name: 'desk-report-group',
    component: () => import(/* webpackChunkName: "desk-report-group" */ '@/views/desk/reports/groupPerformance.vue')

  },
  {
    meta: {
      title: i18n.tc('HelpdeskReport', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/analytics/reports/help-desk',
    name: 'analytics-reports-help-desk',
    component: () => import(/* webpackChunkName: "analytics-reports-help-desk" */ '@/views/desk/reports/Index.vue')

  },
  {
    meta: {
      title: i18n.tc('SettingEmail', 2),
      get featureValue () { return features()?.planHelpUsers ? -1 : 0 }
    },
    path: '/desk/settings/mailbox',
    name: 'desk-mailbox-index',
    component: () => import(/* webpackChunkName: "desk-mailbox-index" */ '@/views/desk/settings/mailbox/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('Survey', 2),
      requiresAuth: false,
      layout: 'layout-blank'
    },
    path: '/survey/response',
    name: 'response-survey',
    component: () => import(/* webpackChunkName: "response-survey" */ '@/views/desk/settings/surveys/ResponseSurvey.vue')
  }
]
