/**
 * Mueve la vista hacia un elemento HTML
 *
 * @param {HTMLElement} el Elemento HTML a enfocar
 * @param {ScrollIntoViewOptions} options
 */
export function goToElement (el, options = {}) {
  if (!el.scrollIntoView) return
  el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start', ...options })
}

/**
 * Espera a que las animaciones y transiciones de un elemento HTML finalicen
 *
 * @param {HTMLElement} el
 * @param {object} [options] Igual a {@link HTMLElement.getAnimations:HTMLElement#getAnimations}, por defecto en `false`
 * @param {boolean} options.subtree
 **/
export async function animationsWaitEnd (el, options = { subtree: false }) {
  await Promise.all(el.getAnimations(options).map(animation => animation.finished))
}

/**
 * Retorna un valor de 0 a 100 equivalente al progreso del scroll
 *
 * @param {HTMLElement} el Elemento scrolable
 **/
export function elementScrollProgressRate (el) {
  if (!el) return 0

  const windowScroll = el.scrollTop
  const height = el.scrollHeight - el.getBoundingClientRect().height

  return (windowScroll / height) * 100
}
