import { Buffer } from 'buffer'

/**
 * Características del plan para el servicio 2 - Aliaddo ERP
 *
 * @author Kristian Alonso Sanchez <kalonsos@aliaddo.com>
 *
 * @typedef {Object} FeaturesRecordErp
 * @property {number} planElectronicTriplet Característica: Documentos electrónicos, ID: 3, Servicio: 2 - Aliaddo ERP
 * @property {number} planInboxManual Característica: Recepción (Manual), ID: 4, Servicio: 2 - Aliaddo ERP
 * @property {number} planInboxAutomatic Característica: Recepción (Automática), ID: 5, Servicio: 2 - Aliaddo ERP
 * @property {number} planInboxControl Característica: Recepción (Control Automática), ID: 6, Servicio: 2 - Aliaddo ERP
 * @property {number} planDocumentPOS Característica: Documentos equivalentes (POS), ID: 7, Servicio: 2 - Aliaddo ERP
 * @property {number} planElectronicPOS Característica: Facturación electrónica (POS), ID: 8, Servicio: 2 - Aliaddo ERP
 * @property {number} planPartialItems Característica: Manejo parcial de productos, ID: 9, Servicio: 2 - Aliaddo ERP
 * @property {number} planMonthlyIncome Característica: Ingresos mensuales, ID: 10, Servicio: 2 - Aliaddo ERP
 * @property {number} planApi Característica: API de integración, ID: 11, Servicio: 2 - Aliaddo ERP
 * @property {number} planUser Característica: Usuarios, ID: 48, Servicio: 2 - Aliaddo ERP
 * @property {number} planCostCenter Característica: Centros de costos, ID: 50, Servicio: 2 - Aliaddo ERP
 * @property {number} planWarehouse Característica: Bodegas, ID: 51, Servicio: 2 - Aliaddo ERP
 * @property {number} planBranch Característica: Sucursales, ID: 52, Servicio: 2 - Aliaddo ERP
 * @property {number} planItem Característica: Inventario, ID: 53, Servicio: 2 - Aliaddo ERP
 * @property {number} planAuditLog Característica: Log de auditoría, ID: 54, Servicio: 2 - Aliaddo ERP
 * @property {number} planSubState Característica: Estados personalizados, ID: 55, Servicio: 2 - Aliaddo ERP
 * @property {number} planSell Característica: Facturas tradicional, ID: 56, Servicio: 2 - Aliaddo ERP
 * @property {number} planElectronicBill Característica: Facturación electrónica, ID: 57, Servicio: 2 - Aliaddo ERP
 * @property {number} planPointOfSale Característica: Puntos de ventas (POS), ID: 58, Servicio: 2 - Aliaddo ERP
 * @property {number} planEmailTemplate Característica: ERP - Plantilla de e-mail personalizadas, ID: 59, Servicio: 2 - Aliaddo ERP
 * @property {number} planCrmEmailTemplate Característica: CRM - Plantillas de e-mail personalizadas, ID: 60, Servicio: 2 - Aliaddo ERP
 * @property {number} planStorage Característica: Archivos adjuntos, ID: 61, Servicio: 2 - Aliaddo ERP
 * @property {number} planBizConsignation Característica: Mercancias en consignación, ID: 62, Servicio: 2 - Aliaddo ERP
 * @property {number} planPaymentReminder Característica: Recordatorio de pagos, ID: 63, Servicio: 2 - Aliaddo ERP
 * @property {number} planManufacture Característica: Producción, ID: 64, Servicio: 2 - Aliaddo ERP
 * @property {number} planCustomFields Característica: Campos personalizados, ID: 65, Servicio: 2 - Aliaddo ERP
 * @property {number} planPromotion Característica: Promociones, ID: 67, Servicio: 2 - Aliaddo ERP
 * @property {number} planCommission Característica: Comisiones, ID: 68, Servicio: 2 - Aliaddo ERP
 * @property {number} planEmailSent Característica: Trazabilidad de e-mails enviados, ID: 70, Servicio: 2 - Aliaddo ERP
 * @property {number} planZonaVirtual Característica: Pagos online (Zona virtual), ID: 71, Servicio: 2 - Aliaddo ERP
 * @property {number} planExportCertificateMassive Característica: Generación masiva de certificados de retención, ID: 72, Servicio: 2 - Aliaddo ERP
 * @property {number} planExportBizDocumentMassive Característica: Exportación masiva de facturas, ordenes, etc. (PDF'S), ID: 73, Servicio: 2 - Aliaddo ERP
 * @property {number} planBackup Característica: Copias de respaldo (Backup), ID: 74, Servicio: 2 - Aliaddo ERP
 * @property {number} planOnlyIfrs Característica: Solo NIIF, ID: 75, Servicio: 2 - Aliaddo ERP
 * @property {number} planProfitability Característica: Rentabilidad, ID: 78, Servicio: 2 - Aliaddo ERP
 * @property {number} planEmployee Característica: Empleados, ID: 92, Servicio: 2 - Aliaddo ERP
 * @property {number} planAsset Característica: Activos Fijos, ID: 93, Servicio: 2 - Aliaddo ERP
 * @property {number} planTax Característica: Liquidación impuestos, ID: 94, Servicio: 2 - Aliaddo ERP
 * @property {number} planExogenous Característica: Información Exógena, ID: 95, Servicio: 2 - Aliaddo ERP
 * @property {number} planCollection Característica: Cobranzas, ID: 97, Servicio: 2 - Aliaddo ERP
 * @property {number} planSupportPhone Característica: Soporte premium - telefono, ID: 98, Servicio: 2 - Aliaddo ERP
 * @property {number} planSupport Característica: Soporte premium - chat, ID: 99, Servicio: 2 - Aliaddo ERP
 * @property {number} planCrm Característica: CRM, ID: 100, Servicio: 2 - Aliaddo ERP
 * @property {number} planSender Característica: Remitentes de respuesta (replyTo), ID: 101, Servicio: 2 - Aliaddo ERP
 * @property {number} planCrmActivities Característica: CRM - Actividades de venta personalizada, ID: 102, Servicio: 2 - Aliaddo ERP
 * @property {number} planCrmMultipleProcesses Característica: CRM - Multiples procesos de venta, ID: 103, Servicio: 2 - Aliaddo ERP
 * @property {number} planCrmManagementTerritories Característica: CRM - Gestión de territorios, ID: 104, Servicio: 2 - Aliaddo ERP
 * @property {number} planCrmAuditLog Característica: CRM - Log de auditoría, ID: 105, Servicio: 2 - Aliaddo ERP
 * @property {number} planCrmAdvanced Característica: CRM - Avanzado, ID: 106, Servicio: 2 - Aliaddo ERP
 * @property {number} planExpense Característica: Gastos, ID: 107, Servicio: 2 - Aliaddo ERP
 * @property {number} planOrderRecurrence Característica: Facturación recurrente, ID: 108, Servicio: 2 - Aliaddo ERP
 * @property {number} planBuy Característica: Compras, ID: 112, Servicio: 2 - Aliaddo ERP
 * @property {number} planTreasury Característica: Cuentas por pagar / cobrar, ID: 116, Servicio: 2 - Aliaddo ERP
 * @property {number} planConversionsUnitMeasure Característica: Múltiples unidades de medida por producto, ID: 126, Servicio: 2 - Aliaddo ERP
 * @property {number} planRequisition Característica: Requisiciones de inventario, ID: 127, Servicio: 2 - Aliaddo ERP
 * @property {number} planSerialAndBatch Característica: Seriales y lotes, ID: 128, Servicio: 2 - Aliaddo ERP
 * @property {number} planAccounting Característica: Contabilidad automática, ID: 133, Servicio: 2 - Aliaddo ERP
 * @property {number} planLogistic Característica: Logística, ID: 134, Servicio: 2 - Aliaddo ERP
 * @property {number} planSupportDocument Característica: Documento soporte, ID: 135, Servicio: 2 - Aliaddo ERP
 * @property {number} planElectronicPayrollErp Característica: Nómina Electrónica, ID: 141, Servicio: 2 - Aliaddo ERP
 * @property {number} planPosRegisters Característica: Cajas (POS), ID: 143, Servicio: 2 - Aliaddo ERP
 * @property {number} planPaymentSchedule Característica: Programación de pagos, ID: 144, Servicio: 2 - Aliaddo ERP
 * @property {number} planAnalyticsGoals Característica: Presupuestos, ID: 145, Servicio: 2 - Aliaddo ERP
 * @property {number} planAnalyticsAccounting Característica: Reclasificación de cuentas y terceros, ID: 146, Servicio: 2 - Aliaddo ERP
 * @property {number} planRenumberingAccounting Característica: Renumeración de asientos contables, ID: 147, Servicio: 2 - Aliaddo ERP
 * @property {number} planSalesRemissions Característica: Remisiones de ventas, ID: 148, Servicio: 2 - Aliaddo ERP
 * @property {number} planSalesAdvances Característica: Anticipo en Ventas, ID: 149, Servicio: 2 - Aliaddo ERP
 * @property {number} planReportGroupByPerson Característica: Cuentas por pagar / cobrar agrupadas por tercero, ID: 150, Servicio: 2 - Aliaddo ERP
 * @property {number} planReportGroupByAccount Característica: Cuentas por pagar / cobrar agrupadas por cuenta contable, ID: 151, Servicio: 2 - Aliaddo ERP
 * @property {number} planCompanies Característica: Empresas, ID: 152, Servicio: 2 - Aliaddo ERP
 * @property {number} planOnlyPOS Característica: Solo POS, ID: 157, Servicio: 2 - Aliaddo ERP
 * @property {number} planDomicile Característica: Domicilios (POS), ID: 158, Servicio: 2 - Aliaddo ERP
 * @property {number} planLoyalty Característica: Fidelización de clientes, ID: 163, Servicio: 2 - Aliaddo ERP
 * @property {number} planMulticurrency Característica: Multimoneda, ID: 195, Servicio: 2 - Aliaddo ERP
 * @property {number} planRecurringAccounting Característica: Recurrencia Contable, ID: 196, Servicio: 2 - Aliaddo ERP
 * @property {number} planRoleBasedSecurity Característica: Seguridad por roles, ID: 199, Servicio: 2 - Aliaddo ERP
 * @property {number} planCertificatesSendMassive Característica: Envío masivo de certificados de retención, ID: 200, Servicio: 2 - Aliaddo ERP
**/

/**
 * Características del plan para el servicio 8 - Aliaddo POS
 *
 * @author Kristian Alonso Sanchez <kalonsos@aliaddo.com>
 *
 * @typedef {Object} FeaturesRecordPos
 * @property {number} planPosRoleBasedSecurity Característica: Seguridad por roles, ID: 182, Servicio: 8 - Aliaddo POS
 * @property {number} planPosPointOfSale Característica: Puntos de venta, ID: 183, Servicio: 8 - Aliaddo POS
 * @property {number} planPosCashRegisters Característica: Cajas registradoras, ID: 184, Servicio: 8 - Aliaddo POS
 * @property {number} planPosDocuments Característica: Documentos, ID: 185, Servicio: 8 - Aliaddo POS
 * @property {number} planPosWarehouse Característica: Bodegas, ID: 186, Servicio: 8 - Aliaddo POS
 * @property {number} planPosItem Característica: Inventarios, ID: 187, Servicio: 8 - Aliaddo POS
 * @property {number} planPosPromotion Característica: Promociones, ID: 188, Servicio: 8 - Aliaddo POS
 * @property {number} planPosConversionsUnitMeasure Característica: Múltiples unidades de medida por producto, ID: 189, Servicio: 8 - Aliaddo POS
 * @property {number} planPosLoyalty Característica: Fidelización de clientes, ID: 190, Servicio: 8 - Aliaddo POS
 * @property {number} planPosDomicile Característica: Domicilios, ID: 191, Servicio: 8 - Aliaddo POS
 * @property {number} planPosSerialAndBatch Característica: Seriales y lotes, ID: 192, Servicio: 8 - Aliaddo POS
 * @property {number} planPosPurchases Característica: Compras, ID: 193, Servicio: 8 - Aliaddo POS
 * @property {number} planPosUsers Característica: Usuarios, ID: 194, Servicio: 8 - Aliaddo POS
 * @property {number} planPosPriceLists Característica: Listas de precio, ID: 208, Servicio: 8 - Aliaddo POS
 * @property {number} planPosCommissions Característica: Comisiones para vendedores, ID: 209, Servicio: 8 - Aliaddo POS
 * @property {number} planPosTips Característica: Propinas, ID: 210, Servicio: 8 - Aliaddo POS
 * @property {number} planPosBillManualReception Característica: Recepción manual, ID: 212, Servicio: 8 - Aliaddo POS
 * @property {number} planPosBillAutomaticReception Característica: Recepción automática, ID: 213, Servicio: 8 - Aliaddo POS
 * @property {number} planPosExpenses Característica: Gestión de gastos, ID: 222, Servicio: 8 - Aliaddo POS
**/

/**
 * Características del plan para el servicio 11 - Nómina Lite
 *
 * @author Kristian Alonso Sanchez <kalonsos@aliaddo.com>
 *
 * @typedef {Object} FeaturesRecordPayrollLite
 * @property {number} planElectronicPayroll Característica: Documentos nómina electrónica, ID: 137, Servicio: 11 - Nómina Lite
**/

/**
 * Consúlta las características del plan desde el `LocalStorage` del navegador
 *
 * @returns {FeaturesRecordErp & FeaturesRecordPos & FeaturesRecordPayrollLite}
 */
export const features = () => localStorage.getItem('planLimits') ? JSON.parse(Buffer.from(localStorage.getItem('planLimits'), 'base64').toString('utf-8')) : null
