import { i18n } from '@/plugins/i18n'

export default [
  {
    meta: {
      title: i18n.tc('AccountStatement')
    },
    path: '/treasuries/statements',
    name: 'treasuries-statements',
    component: () => import(/* webpackChunkName: "treasuries-statements-index" */ '@/views/treasuries/statements/Index.vue')
  },
  {
    meta: {
      title: i18n.tc('PayableAccount', 2)
    },
    path: '/treasuries/:type',
    name: 'treasuries-type',
    component: () => import(/* webpackChunkName: "treasuries-index" */ '@/views/treasuries/Index.vue')
  },
  {
    meta: {
      title: 'Saldos por cuenta'
    },
    path: '/treasuries/balances/by-account',
    name: 'treasuries-balances-by-account',
    component: () => import('@/views/treasuries/balances/ByAccount.vue')
  },
  {
    meta: {
      title: 'Saldos por tercero'
    },
    path: '/treasuries/balances/by-person',
    name: 'treasuries-balances-by-person',
    component: () => import('@/views/treasuries/balances/ByPerson.vue')
  },
  {
    meta: {
      title: i18n.tc('TreasuryByAccount')
    },
    path: '/treasuries/grouped/by-account',
    name: 'treasuries-grouped-by-account',
    component: () => import(/* webpackChunkName: "treasuries-grouped-by-account" */ '@/views/treasuries/grouped/ByAccount.vue')
  },
  {
    meta: {
      title: i18n.tc('TreasuryThirdParty')
    },
    path: '/treasuries/grouped/by-person',
    name: 'treasuries-grouped-by-person',
    component: () => import(/* webpackChunkName: "treasuries-grouped-by-person" */ '@/views/treasuries/grouped/ByPerson.vue')
  }
]
